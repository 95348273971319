/* eslint-disable prefer-template */
import axios from '@axios'

// const apiUrl = 'https://us-central1-entregasrayo.cloudfunctions.net/serverPartners'
// const apiUrl = 'http://localhost:8080'
const { apiUrl } = this.$store.state.appConfig
const apiUrlSummary = 'https://southamerica-east1-rayo-api.cloudfunctions.net/alternativeApisMongoDb'
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // fetchUsers(ctx, queryParams) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .get('/apps/user/users', { params: queryParams })
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios.get(apiUrl + '/getRayos', { params: queryParams })
        // axios.get('http://localhost:8080/getRayos', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    toggleUserStatus(a, params) {
      // console.log(params.id)
      return new Promise((resolve, reject) => {
        axios
          .post(apiUrl + '/toggleUserStatus', { id: params.id, status: params.status })
          // .post('http://localhost:8080/toggleUserStatus', { id: params.id, status: params.status })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createRayo(a, params) {
      // console.log(params.id)
      return new Promise((resolve, reject) => {
        axios
          .post(apiUrl + '/toggleUserStatus', { id: params.id, status: params.status })
          // .post('http://localhost:8080/toggleUserStatus', { id: params.id, status: params.status })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // fetchUser(ctx, { id }) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .get(`/apps/user/users/${id}`)
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },
    fetchUser(ctx, { id }) {
      // console.log(id)
      return new Promise((resolve, reject) => {
        axios.get(apiUrl + `/getRayo/${id}`)
        // axios.get(`http://localhost:8080/getRayo/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUserSumary(ctx, { id }) {
      // console.log(id)
      return new Promise((resolve, reject) => {
        axios.post(apiUrlSummary + '/getRayoSummaryByDateAndStatusIn', { rayoId: id })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/user/users', { user: userData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
